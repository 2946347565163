import dockerImage from '../../assets/skills/docker.png';
import kubernetes from '../../assets/skills/kubernetes.png';
import docker from '../../assets/skills/docker.png';
import gitlab from '../../assets/skills/gitlab.png';
import postgresl from '../../assets/skills/postgresql.png';
import linux from '../../assets/skills/linux.png';
import angular from '../../assets/skills/angular.png';
import bootstrap from '../../assets/skills/bootstrap.png';
import csharp from '../../assets/skills/csharp.png';
import css from '../../assets/skills/css.png';
import cypress from '../../assets/skills/cypress.png';
import figma from '../../assets/skills/figma.png';
import gcp from '../../assets/skills/gcp.png';
import html from '../../assets/skills/html.png';
import illustrator from '../../assets/skills/illustrator.png';
import jasmine from '../../assets/skills/jasmine.png';
import joomla from '../../assets/skills/joomla.png';
import jquery from '../../assets/skills/jquery.png';
import materialui from '../../assets/skills/materialui.png';
import octopus from '../../assets/skills/octopus.png';
import photoshop from '../../assets/skills/photoshop.png';
import react from '../../assets/skills/react.png';
import sass from '../../assets/skills/sass.png';
import sql from '../../assets/skills/sql.png';
import typescript from '../../assets/skills/typescript.png';
import visualstudio from '../../assets/skills/visualstudio.png';
import vscode from '../../assets/skills/vscode.png';
import windowsserver from '../../assets/skills/windowsserver.png';
import wsl2 from '../../assets/skills/wsl2.png';
import wordpress from '../../assets/skills/wordpress.png';
import xd from '../../assets/skills/xd.png';
import indesign from '../../assets/skills/indesign.png';
import firewall from '../../assets/skills/firewall.png';
import vmware from '../../assets/skills/vmware.png';
import microsoft365 from '../../assets/skills/microsoft365.png';
import azure from '../../assets/skills/azure.png';

export function getSkillItems() {
    return [

        {
            id: 7,
            name: "Angular",
            image: angular,
            color: '#c0d7e9',
            rating: 5,
            category: 'Frontend'
        },
        {
            id: 8,
            name: "React",
            image: react,
            color: '#c0d7e9',
            rating: 4,
            category: 'Frontend'
        },
        {
            id: 9,
            name: "jQuery",
            image: jquery,
            color: '#c0d7e9',
            rating: 5,
            category: 'Frontend'
        },
        {
            id: 10,
            name: "Typescript",
            image: typescript,
            color: '#c0d7e9',
            rating: 4,
            category: 'Frontend'
        },
        {
            id: 11,
            name: "Cypress",
            image: cypress,
            color: '#c0d7e9',
            rating: 3,
            category: 'Frontend'
        },
        {
            id: 12,
            name: "Jasmine",
            image: jasmine,
            color: '#c0d7e9',
            rating: 3,
            category: 'Frontend'
        },
        {
            id: 13,
            name: "Testing Library",
            image: octopus,
            color: '#c0d7e9',
            rating: 3,
            category: 'Frontend'
        },
        {
            id: 14,
            name: "HTML",
            image: html,
            color: '#c0d7e9',
            rating: 5,
            category: 'Frontend'
        },
        {
            id: 15,
            name: "CSS",
            image: css,
            color: '#c0d7e9',
            rating: 5,
            category: 'Frontend'
        },
        {
            id: 16,
            name: "Scss",
            image: sass,
            color: '#c0d7e9',
            rating: 5,
            category: 'Frontend'
        },
        {
            id: 17,
            name: "Bootstrap",
            image: bootstrap,
            color: '#c0d7e9',
            rating: 5,
            category: 'Frontend'
        },
        {
            id: 18,
            name: "Material UI",
            image: materialui,
            color: '#c0d7e9',
            rating: 5,
            category: 'Frontend'
        },

        {
            id: 19,
            name: "C#",
            image: csharp,
            color: '#c0d7e9',
            rating: 5,
            category: 'Backend'
        },
        {
            id: 20,
            name: "SQL",
            image: sql,
            color: '#c0d7e9',
            rating: 5,
            category: 'Backend'
        },
        {
            id: 21,
            name: "Joomla",
            image: joomla,
            color: '#c0d7e9',
            rating: 5,
            category: 'Backend'
        },
        {
            id: 22,
            name: "Wordpress",
            image: wordpress,
            color: '#c0d7e9',
            rating: 4,
            category: 'Backend'
        },

        {
            id: 1,
            name: "Kubernetes",
            image: kubernetes,
            color: '#d5e4f9',
            rating: 4,
            category: 'DevOps'
        },
        {
            id: 2,
            name: "Docker",
            image: docker,
            color: '#c0d7e9',
            rating: 4,
            category: 'DevOps'
        },
        {
            id: 3,
            name: "Gitlab",
            image: gitlab,
            color: '#c0d7e9',
            rating: 4,
            category: 'DevOps'
        },
        {
            id: 4,
            name: "PostgreSQL",
            image: postgresl,
            color: '#c0d7e9',
            rating: 4,
            category: 'DevOps'
        },
        {
            id: 5,
            name: "Linux",
            image: linux,
            color: '#c0d7e9',
            rating: 4,
            category: 'DevOps'
        },
        {
            id: 6,
            name: "GCP",
            image: gcp,
            color: '#c0d7e9',
            rating: 3,
            category: 'DevOps'
        },
        {
            id: 35,
            name: "Azure",
            image: azure,
            color: '#c0d7e9',
            rating: 3,
            category: 'DevOps'
        },

        {
            id: 23,
            name: "VS Code",
            image: vscode,
            color: '#c0d7e9',
            rating: 5,
            category: 'Sonstiges'
        },
        {
            id: 24,
            name: "Visual Studio",
            image: visualstudio,
            color: '#c0d7e9',
            rating: 5,
            category: 'Sonstiges'
        },
        {
            id: 26,
            name: "WSL2",
            image: wsl2,
            color: '#c0d7e9',
            rating: 5,
            category: 'Sonstiges'
        },
        {
            id: 27,
            name: "Figma",
            image: figma,
            color: '#c0d7e9',
            rating: 3,
            category: 'Sonstiges'
        },

        {
            id: 29,
            name: "Photoshop",
            image: photoshop,
            color: '#c0d7e9',
            rating: 3,
            category: 'Adobe CC'
        },
        {
            id: 30,
            name: "InDesign",
            image: indesign,
            color: '#c0d7e9',
            rating: 3,
            category: 'Adobe CC'
        },
        {
            id: 31,
            name: "Adobe XD",
            image: xd,
            color: '#c0d7e9',
            rating: 5,
            category: 'Adobe CC'
        },
        {
            id: 32,
            name: "Illustrator",
            image: illustrator,
            color: '#c0d7e9',
            rating: 2,
            category: 'Adobe CC'
        },

        {
            id: 35,
            name: "Microsoft 365",
            image: microsoft365,
            color: '#c0d7e9',
            rating: 5,
            category: 'Systemadministration'
        },
        {
            id: 28,
            name: "Windows Server",
            image: windowsserver,
            color: '#c0d7e9',
            rating: 4,
            category: 'Systemadministration'
        },
        {
            id: 33,
            name: "Firewall (Lancom)",
            image: firewall,
            color: '#c0d7e9',
            rating: 4,
            category: 'Systemadministration'
        },
        {
            id: 34,
            name: "VMWare",
            image: vmware,
            color: '#c0d7e9',
            rating: 4,
            category: 'Systemadministration'
        },
    ];
}

export function getSkillItemsByCategory(category: string){
    return getSkillItems().filter(x => x.category === category);
}

export function getCategories(){
    return [
        {
            name: 'Frontend',
            id: 2,
            rowSpan: 3,
        },
        {
            name: 'Backend',
            id: 3,
            rowSpan: 1,
        },
        {
            name: 'Adobe CC',
            id: 4,
            rowSpan: 1,
        },
        {
            name: 'DevOps',
            id: 1,
            rowSpan: 2
        },
        {
            name: 'Systemadministration',
            id: 5,
            rowSpan: 1,
        },
        {
            name: 'Sonstiges',
            id: 4,
            rowSpan: 1,
        },
    ]
}