import '../../shared/project-page.css';
import './05-project-wirhelfen.css';
import Plx from 'react-plx';

import image1 from '../../assets/projects/wirhelfen/image1.png';
import image2 from '../../assets/projects/wirhelfen/image2.png';
import image3 from '../../assets/projects/wirhelfen/image3.png';
import image4 from '../../assets/projects/wirhelfen/image4.png';
import image5 from '../../assets/projects/wirhelfen/image5.png';

import { Parallax, useParallax } from 'react-scroll-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';

const textData = [
    {
      start: '.trigger1',
      duration: '30vh',
      properties: [
        {
          startValue: 30,
          endValue: 0,
          unit: 'vmin',
          property: 'translateY',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: '.trigger1',
      startOffset: '250vh',
      duration: '30vh',
      properties: [
        {
          startValue: 0,
          endValue: -30,
          unit: 'vh',
          property: 'translateY',
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
        },
      ],
    },
    {
      start: '.trigger1',
      startOffset: '280vh',
      duration: '1vh',
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: 'scale',
        },
      ],
    },
  ];

export function ProjectWirhelfen(){
    
    const image1Ref = useParallax<HTMLDivElement>({ speed: -20}).ref;
    const image2Ref = useParallax<HTMLDivElement>({ speed: 40 }).ref;
    // const image3Ref = useParallax<HTMLDivElement>({ speed: 20 }).ref;
    const image4Ref = useParallax<HTMLDivElement>({ speed: 40 }).ref;
    const image5Ref = useParallax<HTMLDivElement>({ speed: -20 }).ref;

    return (
        <div className="projectOuterWrapper wirhelfenWrapper">
            <div className='StickyText-trigger trigger1' />
                <Plx
                className="projectWrapper-custom"
                    parallaxData={ textData }
                >
            <section className="projectWrapper">
                <div className="projectNumber">1</div>
                <div className="projectText">
                    <h2 className="projectHeader">
                        WirHelfen
                    </h2>
                    <div className="projectTags">
                        <div className="projectTags-tag">Gitlab Pipelines</div>
                        <div className="projectTags-tag">DevOps</div>
                        <div className="projectTags-tag">UI/UX</div>
                        <div className="projectTags-tag">Teamleitung</div>
                    </div>
                    <div className="projectDescription">
                        <p className='mt-1'>Eine Onlineplattform, welche 2021 von Ehrenamtlichen für bspw. die Opfer der Flutkatastrophe entstanden ist.</p>
                        <p className='mt-1'>Die erste Version wurde nach einer Woche online gestellt. Innerhalb von zwei Monaten erweiterten und vervollständigten wir die Plattform.</p>
                        <p className='mt-1'>Ich konzeptionierte und entwarf hierbei den verwendeten Techstack.</p>
                        <p className='mt-1'>Ebenso leitete ich das Team bestehend aus Front- und Backendentwickler:Innen sowie UI/UX Designer:Innen.</p>
                    </div>
                    <div className="md:mt-8 mt-3" style={{position: 'relative'}}>
                      <a className="button" rel="noreferrer" target="_blank" href="https://wirhelfen.eu">Zur Seite <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></a>
                    </div>
                </div>
            </section>
            </Plx>

            <div className="projectImages">
                <div ref={image1Ref} style={{marginTop: '100vh', marginRight: "2vw", width: "40vw", minWidth: "320px"}} className="small-left"><img className="projectImage" src={image1}/></div>
                <div ref={image4Ref} style={{marginTop: '100vh', marginRight: "max(250px, 35vw)", width: "20vw", minWidth: "100px"}}><img className="projectImage" src={image4}/></div>

                <div ref={image5Ref} style={{marginTop: '230vh', marginRight: "2vw", width: "40vw", minWidth: "320px"}} className="small-left"><img className="projectImage" src={image5}/></div>
                <div ref={image2Ref} style={{marginTop: '200vh', marginRight: "max(100px, 20vw)", width: "40vw", minWidth: "300px"}}><img className="projectImage" src={image2}/></div>
            </div>
        </div>
    )
}