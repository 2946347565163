import Plx from 'react-plx';
import { useParallax } from 'react-scroll-parallax';
import './project-ep.css';

import image1 from '../../assets/projects/ep/image1.png';
import image2 from '../../assets/projects/ep/image2.png';
import image3 from '../../assets/projects/ep/image3.png';
import image4 from '../../assets/projects/ep/image4.png';
import image5 from '../../assets/projects/ep/image5.png';

const textData = [
    {
      start: '.trigger3',
      duration: '30vh',
      properties: [
        {
          startValue: 30,
          endValue: 0,
          unit: 'vh',
          property: 'translateY',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: '.trigger3',
      startOffset: '220vh',
      duration: '30vh',
      properties: [
        {
          startValue: 0,
          endValue: -30,
          unit: 'vh',
          property: 'translateY',
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
        },
      ],
    },
    {
      start: '.trigger3',
      startOffset: '250vh',
      duration: '1vh',
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: 'scale',
        },
      ],
    },
  ];

export function ProjectEp() {
    const image1Ref = useParallax<HTMLDivElement>({ speed: -10}).ref;
    const image2Ref = useParallax<HTMLDivElement>({ speed: -10 }).ref;
    const image3Ref = useParallax<HTMLDivElement>({ speed: 20 }).ref;

    return (
        <div className="projectOuterWrapper epWrapper">
            <div className='StickyText-trigger trigger3' />
                <Plx
                className="projectWrapper-custom"
                    parallaxData={ textData }
                >
            <section className="projectWrapper">
                <div className="projectNumber">3</div>
                <div className="projectText">
                    <h2 className="projectHeader">
                      Prozessmanagement <br />
                      ERP-Modul
                    </h2>
                    <div className="projectTags">
                        <div className="projectTags-tag">Figma</div>
                        <div className="projectTags-tag">Angular</div>
                        <div className="projectTags-tag">C#</div>
                        <div className="projectTags-tag">DevOps</div>
                    </div>
                    <div className="projectDescription">
                        <p className="mt-1">Besprechen und Konzeptionieren verschiedener Lösungsansätze (SQL/NOSQL, Cloud/On Premise, Monolythisch/Microservice, Web/Terminal Server).</p>
                        <p className="mt-1">Erstellung diverse Figma Entwürfe und Meetings mit dem Kunden, um die Arbeitsabläufe- und Prozesse zu verstehen und in der Software abzubilden.</p>
                    </div>
                </div>
            </section>
            </Plx>

            <div className="projectImages">

                <div ref={image1Ref} style={{marginTop: '50vh', marginRight: "2vw", width: "40vw", minWidth: "320px"}}><img className="projectImage" src={image4}/></div>

                <div ref={image2Ref} style={{marginTop: '180vh', marginRight: "2vw", width: "40vw", minWidth: "320px"}}><img className="projectImage" src={image2}/></div>

                <div ref={image3Ref} style={{marginTop: '130vh', marginRight: "max(0px, 10vw)", width: "max(20vw, 300px)", minWidth: "100px"}}><img className="projectImage" src={image5}/></div>
                

            </div>
        </div>
    );
}