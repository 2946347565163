import '../projectdetails.css';
import onigThumbnail from '../../../assets/moreprojects/onig/image1.png';
import detailsImage2 from '../../../assets/moreprojects/onig/image2.png';
import detailsImage3 from '../../../assets/moreprojects/onig/image3.png';
import detailsImage4 from '../../../assets/moreprojects/onig/image4.png';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Onigdetails(){
    return (
        <div className="details-card">
            <div className="details-container">
                <div className="details-bigImage">
                    <img src={onigThumbnail} />
                </div>
                <div className="details-date">Stand: 09/2017</div>
                <div className="details-title"><h2>Webpräsenz - Systemhaus ONIG</h2></div>
                <div className="details-tags projectTags">
                    <div className="projectTags-tag">Joomla</div>
                    <div className="projectTags-tag">Php</div>
                </div>
                <div className="details-details">
                    <p className="mt-1">Das Konzept der Open Network IT Group ist ein Netzwerk bestehend aus verschiedenen Fachkräften mit unterschiedlichen Kompetenzbereichen, um den Kunden ein breites Portfolio anbieten zu können.</p>
                    <p className="mt-1">Heute bietet das Systemhaus ONIG eine vollumfängliche Abdeckung der erforderlichen IT-Infrastruktur an.</p>
                    <div className="mt-3">
                        <a className="button" href="https://onig.eu" target="_blank">Zur Seite <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></a>
                    </div>
                </div>
                <div className="details-smallimages smallimages-2-1">
                    <div className="details-smallimage1">
                        <img src={detailsImage2} />
                    </div>
                    <div className="details-smallimage1">
                        <img src={detailsImage3} />
                    </div>
                </div>
            </div>
        </div>
    )
}