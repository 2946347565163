import './08-aboutme.css';
import profilePicture from '../../assets/portrait_cut.png';
import { CodeBlock, dracula } from 'react-code-blocks'
import Plx from 'react-plx';
import arrow1 from '../../assets/arrows/arrow1.svg';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import cvPdf from '../../assets/julian_forster_cv.pdf';

const bubbleData1 = [
    {
      start: 'self',
      duration: '100vh',
      properties: [
        {
          startValue: 20,
          endValue: 30,
          unit: 'px',
          property: 'translateY',
        }
      ],
    },
  ];
const bubbleData2 = [
    {
      start: 'self',
      duration: '100vh',
      properties: [
        {
          startValue: 230,
          endValue: 250,
          unit: 'px',
          property: 'translateY',
        }
      ],
    },
  ];
const bubbleData3 = [
    {
      start: 'self',
      duration: '100vh',
      properties: [
        {
          startValue: 240,
          endValue: 260,
          unit: 'px',
          property: 'translateY',
        }
      ],
    },
  ];

export function AboutMe(){

  function openModal() {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: <p>Über mich 😄</p>,
      showCloseButton: true,
      showConfirmButton: false,
      focusDeny: false,
      focusConfirm: true,
      customClass: {
        popup: 'modal-lg',
      },
      html: (
        <>
          <div className="aboutme-details">
              <div className="aboutme-programmer">
                <h4>Code 📟</h4>
                <CodeBlock
                  language="js"
                  text={`const hobbies = [ '🥋', '👨‍💻']
const favoriteFood = '🍝'
const favoriteAnimal = '🐼'

function live(){
  eat(🍝)
  code(🔢)
  if( needsSleep() )
    // sleep(💤)
    code(🔢)
}`}
                  codeBlock
                  showLineNumbers={false}
                  theme={dracula}
                  />
              </div>

              <div className="aboutme-nonprogrammer">
                <h4>Übersetzung 🈸 </h4>
                <p><b>Hobbies:</b><br />Kampfkunst 🥋, Programmieren 👨‍💻</p>
                <p><b>Lieblingsessen:</b><br />Spaghetti Pomodoro 🍝</p>
                <p><b>Lieblingstier:</b><br />Panda 🐼</p>
                <p><br /></p>
                <p><b>Mein Motto:</b></p>
                <p>Programmieren</p>
                <p>Essen</p>
                <p><s>Schlafen</s> <i>Programmieren</i></p>
              </div>

          </div>
        </>
      ),
    })
  }


    return (
        <>
        <section className="aboutme">

            <h2 className="aboutme-title">
                Kontaktinformationen
            </h2>
            
            <div className="aboutme-grid">
                <div className="aboutme-contact">
                    <div className="aboutme-contact-card">
                        <div className="aboutme-contact-card-image">
                            <img src={profilePicture} />
                        </div>
                        <span className="aboutme-contact-card-name">
                            Julian Forster
                        </span>
                        <div className="aboutme-contact-card-details">
                            <p>📧</p>
                            <p><a href="mailto:j.forster@onig.eu">j.forster@onig.eu</a></p>

                            <p>☎️</p>
                            <p><a href="tel:+4917621556223">+49 176 2155 6223</a></p>

                            <p>🌐</p>
                            <p><a target="_blank" href="https://nindo-bewerbung.de">www.nindo-bewerbung.de</a></p>

                        </div>

                        <div className="aboutme-contact-card-cv">
                            <a className="button" target="_blank" href={cvPdf}>CV Herunterladen <FontAwesomeIcon icon={faFilePdf} /></a>
                          </div>
                    </div>
                    <div className="aboutme-contact-dots">
                        <Plx parallaxData={ bubbleData1 }>
                            <div className="aboutme-contact-dots-dot" style={{
                                width: 60,
                                height: 60,
                                top: '15%',
                                left: 50,
                            }}></div>
                        </Plx>
                        <Plx parallaxData={ bubbleData2 }>
                        <div className="aboutme-contact-dots-dot" style={{
                            width: 30,
                            height: 30,
                            top: '80%',
                            left: 50,
                        }}></div>
                        </Plx>
                        <Plx parallaxData={ bubbleData3 }>
                        <div className="aboutme-contact-dots-dot" style={{
                            width: 15,
                            height: 15,
                            top: '85%',
                            left: '90%',
                        }}></div>
                        </Plx>
                    </div>
                </div>

                <div>
                  <button type="button" className='button mt-10' onClick={() => openModal()}>Mehr über mich <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></button>
                </div>
            </div>

        </section>
        </>
    );
}