import { Rating } from 'react-simple-star-rating';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getCategories, getSkillItemsByCategory } from '../03-skills/skill-items';
import './07-more-projects.css';
import { Onigdetails } from './onigdetails/onigdetails';
import FsLightbox from 'fslightbox-react-custom';
import { useState } from 'react';
import { SozialcoachingDetails } from './sozialcoachingdetails/sozialcoachingdetails';
import { RiedlDetails } from './riedldetails/riedldetails';
import { MMSwobodaDetails } from './mmswobodadetails/mmswobodadetails';

// import mmswobodaThumbnail from '../../assets/moreprojects/mmswoboda/image1.png';
// import riedlThumbnail from '../../assets/moreprojects/riedl/image1.png';
import sozialcoachingThumbnail from '../../assets/moreprojects/sozialcoaching/image1.png';
import onigThumbnail from '../../assets/moreprojects/onig/image1.png';

export function MoreProjects(){

    const sourcesArray = [
            <Onigdetails />,
            <SozialcoachingDetails />,
            // <RiedlDetails />,
            // <MMSwobodaDetails />
    ] as any[];

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openDetails(index: number){
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    return (
        <>
        <section className="moreProjects">
            <h2 className="moreProjects-title">
                Weitere Projekte
            </h2>

            <div className="moreProjects-projectWrapper">

                <div className="moreProjects-projectentry" onClick={() => openDetails(1)}
                    style={{
                        backgroundImage: `url(${onigThumbnail})`
                    }}>
                    
                    <div className="moreProjects-projectentry-title">
                        <span>
                            Webpräsenz <br />
                            <span className="moreProjects-projectentry-title-company">Systemhaus ONIG</span></span>
                    </div>
                </div>
                <div className="moreProjects-projectentry" onClick={() => openDetails(2)}
                style={{
                    backgroundImage: `url(${sozialcoachingThumbnail})`
                }}>
                    
                    <div className="moreProjects-projectentry-title">
                        <span>
                            Webpräsenz <br />
                            <span className="moreProjects-projectentry-title-company">Sozialcoaching München</span></span>
                    </div>
                </div>
                {/* <div className="moreProjects-projectentry" onClick={() => openDetails(3)}
                    style={{
                        backgroundImage: `url(${riedlThumbnail})`
                    }}>
                    
                    <div className="moreProjects-projectentry-title">
                       <span>
                           Webpräsenz<br />
                           <span className="moreProjects-projectentry-title-company">Riedl Feinmechanik</span>
                        </span>
                    </div>
                </div>
                <div className="moreProjects-projectentry" onClick={() => openDetails(4)}
                    style={{
                        backgroundImage: `url(${mmswobodaThumbnail})`
                    }}>
                    
                    <div className="moreProjects-projectentry-title">
                        <span>
                            Webpräsenz<br />
                            <span className="moreProjects-projectentry-title-company">MM Swoboda</span></span>
                    </div>
                </div> */}

            </div>
        </section>

        <FsLightbox
            toggler={lightboxController.toggler}
            sources={sourcesArray}
            slide={lightboxController.slide}
            />
        </>
    );
}