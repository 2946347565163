import '../projectdetails.css';
import sozialcoachingThumbnail from '../../../assets/moreprojects/sozialcoaching/image1.png';
import smallImage2 from '../../../assets/moreprojects/sozialcoaching/image2.png';
import smallImage3 from '../../../assets/moreprojects/sozialcoaching/image3.png';
import smallImage4 from '../../../assets/moreprojects/sozialcoaching/image4.svg';

export function SozialcoachingDetails(){
    return (
        <div className="details-card">
            <div className="details-container">
                <div className="details-bigImage">
                    <img src={sozialcoachingThumbnail} />
                </div>
                <div className="details-date">Stand: 04/2019</div>
                <div className="details-title"><h2>Webpräsenz - Sozialcoaching München</h2></div>
                <div className="details-tags projectTags">
                    <div className="projectTags-tag">Wordpress</div>
                    <div className="projectTags-tag">Php</div>
                </div>
                <div className="details-details">
                    <p>Sozialcoaching München beschäftigte sich primär mit Kinder und Jugendlichen, um diese in deren Persönlichkeitsentwicklung zu unterstützen.</p>
                    <p className="mt-1">Hier wurde ein vollumfängliches Branding entwickelt inkl. Logo und zwei digitalen Assistenten (einer der beiden ist im unteren Bild zu sehen).</p>
                    <p className="mt-1">Diese Grafiken wurden in Zusammenarbeit mit dem Kunden entwickelt und entworfen. Es wurden dabei viele verschiedene Konzepte und Ideen für die einzelnen Seiten entworfen und Prototypen entwickelt.</p>
                    <p className="mt-1">Abschließend wurde sich auf ein sehr simples und einfaches Design entschieden, die tieferen Themen wurden dann in Printmedien behandelt.</p>
                    <p className="mt-1">Die Seite bot eine Anmeldung für Teilnehmer an, um auf Kursmaterialien zugreifen zu können sowie anonyme Bewertungen abzugeben.</p>
                    <p className="mt-1">Das Unternehmen wurde aufgekauft und daher ist die Präsenz nicht mehr online. Die neue Präsenz wurde ebenfalls von uns gestaltet und betreut, diese dürfen wir jedoch Aufgrund des Datenschutzes nicht präsentieren.</p>
                </div>
                <div className="details-smallimages">
                    <div className="details-smallimage1">
                        <img src={smallImage2} />
                    </div>
                    <div className="details-smallimage1">
                        <img src={smallImage3} />
                    </div>
                    <div className="details-smallimage1">
                    <img src={smallImage4} />
                    </div>
                </div>
            </div>
        </div>
    )
}