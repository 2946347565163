import './03-skills.css'
import { Skill } from './skill/skill'

import kubernetesImage from '../../assets/skills/kubernetes.png'
import dockerImage from '../../assets/skills/docker.png'
import gitlabImage from '../../assets/skills/gitlab.svg'
import postgresqlImage from '../../assets/skills/postgresql.png'
import linuxImage from '../../assets/skills/linux.png'
import gcpImage from '../../assets/skills/gcp.png'
import { Parallax } from 'react-scroll-parallax'
import { usePopper,  } from 'react-popper'

import React, { LegacyRef } from "react";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Rating } from '../../components/rating/rating'
import {
  getCategories,
  getSkillItems,
  getSkillItemsByCategory,
} from './skill-items'

import { Popover } from '@headlessui/react'
import { useState } from 'react'

export function Skills() {
  function openModal() {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: <p>Skills - Details</p>,
      showCloseButton: true,
      showConfirmButton: false,
      focusDeny: false,
      focusConfirm: true,
      customClass: {
        popup: 'modal-lg',
      },
      html: (
        <>
          <div className="skill-detail-wrapper">
            {getCategories().map((category) => (
              <div
                className="skill-detail"
                key={category.id}
                style={{ gridRow: `span ${category.rowSpan}` }}
              >
                <div className="skill-detail-title">{category.name}</div>
                <div className="skill-detail-list">
                  {getSkillItemsByCategory(category.name).map((skill) => (
                    <div className="skill-detail-list-element" key={skill.id}>
                      <div className="skill-detail-list-element-name">
                        {skill.name}
                      </div>
                      <div className="skill-detail-list-element-rating">
                        <Rating count={skill.rating} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ),
    })
  }

  const popperConfig = {
    placement: 'right-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'bottom'],
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 30],
        }
      }
    ]
  };

  let [webentwicklungElement, setwebentwicklungElement] = useState<HTMLButtonElement | null>()
  let [webentwicklungpopperElement, setwebentwicklungpopperElement] = useState<HTMLDivElement | null>()
  let webentiwcklungPopper = usePopper(webentwicklungElement, webentwicklungpopperElement, popperConfig as any);

  let [erpcrmElement, seterpcrmElement] = useState<HTMLButtonElement | null>()
  let [erpcrmpopperElement, seterpcrmpopperElement] = useState<HTMLDivElement | null>()
  let erpcrmPopper = usePopper(erpcrmElement, erpcrmpopperElement, popperConfig as any);

  let [itmanagementElement, setitmanagementElement] = useState<HTMLButtonElement | null>()
  let [itmanagementpopperElement, setitmanagementpopperElement] = useState<HTMLDivElement | null>()
  let itmanagementPopper = usePopper(itmanagementElement, itmanagementpopperElement, popperConfig as any);

  let [mitgliederElement, setmitgliederElement] = useState<HTMLButtonElement | null>()
  let [mitgliederpopperElement, setmitgliederpopperElement] = useState<HTMLDivElement | null>()
  let mitgliederPopper = usePopper(mitgliederElement, mitgliederpopperElement, popperConfig as any);

  let [abiturElement, setabiturElement] = useState<HTMLButtonElement | null>()
  let [abiturpopperElement, setabiturpopperElement] = useState<HTMLDivElement | null>()
  let abiturPopper = usePopper(abiturElement, abiturpopperElement, popperConfig as any);

  let [uebernahmeElement, setuebernahmeElement] = useState<HTMLButtonElement | null>()
  let [uebernahmepopperElement, setuebernahmepopperElement] = useState<HTMLDivElement | null>()
  let uebernahmePopper = usePopper(uebernahmeElement, uebernahmepopperElement, popperConfig as any);

  let [wirhelfenElement, setwirhelfenElement] = useState<HTMLButtonElement | null>()
  let [wirhelfenpopperElement, setwirhelfenpopperElement] = useState<HTMLDivElement | null>()
  let wirhelfenPopper = usePopper(wirhelfenElement, wirhelfenpopperElement, popperConfig as any);

  let [vertragElement, setvertragElement] = useState<HTMLButtonElement | null>()
  let [vertragpopperElement, setvertragpopperElement] = useState<HTMLDivElement | null>()
  let vertragPopper = usePopper(vertragElement, vertragpopperElement, popperConfig as any);

  let [gruendungElement, setgruendungElement] = useState<HTMLButtonElement | null>()
  let [gruendungpopperElement, setgruendungpopperElement] = useState<HTMLDivElement | null>()
  let gruendungPopper = usePopper(gruendungElement, gruendungpopperElement, popperConfig as any);

  let [erpmodulElement, seterpmodulElement] = useState<HTMLButtonElement | null>()
  let [erpmodulpopperElement, seterpmodulpopperElement] = useState<HTMLDivElement | null>()
  let erpmodulPopper = usePopper(erpmodulElement, erpmodulpopperElement, popperConfig as any);

  return (
    <section className="skills">
      <h2 className="skills-title">Skills & Erfahrungen</h2>
      <h2 className="skills-title-mobile skills-title-mobile-1">Skills</h2>
      <div className="skills-skills-wrapper">
        <div className="skills-skills">
          {getSkillItems().map((item) => (
            <Skill
              title={item.name}
              key={item.id}
              image={item.image}
              backgroundColor={item.color}
            ></Skill>
          ))}
        </div>
        <div className="skills-skills-more">
          <div className="skills-skills-more-line"></div>
          <div className="skills-skills-more-button">
            <button
              className="button"
              type="button"
              onClick={() => openModal()}
            >
              Mehr Details{' '}
              <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
            </button>
          </div>
        </div>
      </div>
      <h2 className="skills-title-mobile skills-title-mobile-2">Erfahrungen</h2>
      <div className="skills-erfahrungen">
        <div className="skills-erfahrungen-year">
          <div className="skills-erfahrungen-year-number">2017</div>
          <div className="skills-erfahrungen-year-entry">
            <Popover className="">
              <Popover.Button ref={setwebentwicklungElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Webentwicklung
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    03/2017 - 08/2019
                    <ul>
                      <li>Sozialcoaching München</li>
                      <li>Kampfkunstschule Eumyangmukwan</li>
                      <li>Systemhaus ONIG</li>
                    </ul>
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setwebentwicklungpopperElement}
                style={webentiwcklungPopper.styles.popper}
                {...webentiwcklungPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Intensives Auseinandersetzen mit Webentwicklung inkl. CMS Systemen, Struktur, Kommunikation über das Internet und grundlegendes Hosting
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="">
              <Popover.Button ref={seterpcrmElement} className="w-full">
              <div className="skills-erfahrungen-year-entry-tile">
                <span className="skills-erfahrungen-year-entry-title">
                  Entwicklung eines ERP/CRM Systems
                </span>
                <div className="skills-erfahrungen-year-entry-description">
                  03/2018 - 09/2019 bei Systemhaus ONIG
                </div>
              </div>
              </Popover.Button>

              <Popover.Panel
                ref={seterpcrmpopperElement}
                style={erpcrmPopper.styles.popper}
                {...erpcrmPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Autodidaktisches Erarbeiten zur Webentwicklung mit Themen wie: REST-API, CI/CD, Einbindung in Drittanbieter Software, Export zu PDF/Excel
                </div>
              </Popover.Panel>
            </Popover>

          </div>
        </div>

        <div className="skills-erfahrungen-year">
          <div className="skills-erfahrungen-year-number">2018</div>
          <div className="skills-erfahrungen-year-entry">

            <Popover className="">
              <Popover.Button ref={setitmanagementElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    IT-Management & IT-Support
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    03/2018 - 09/2019 bei Systemhaus ONIG
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setitmanagementpopperElement}
                style={itmanagementPopper.styles.popper}
                {...itmanagementPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Projektplanung und Durchführung in der IT-Infrastruktur bei Kunden. Fähigkeit für Kundengespräche erworben. Analytische Fähigkeit zur Fehlerfindung ausgebaut.
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="">
              <Popover.Button ref={setmitgliederElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Erstellung eines Mitgliedermanagement Systems
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    04/2018 - 04/2019 bei Kampfkunstschule Eumyangmukwan
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setmitgliederpopperElement}
                style={mitgliederPopper.styles.popper}
                {...mitgliederPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Erweitern meiner Fähigkeiten zur Webentwicklung. Erste Auseinandersetzung mit Frontend-Frameworks wie Angular und React sowie UI/UX Prototypen in Adobe XD
                </div>
              </Popover.Panel>
            </Popover>

          </div>
        </div>

        <div className="skills-erfahrungen-year">
          <div className="skills-erfahrungen-year-number">2019</div>
          <div className="skills-erfahrungen-year-entry">

            <Popover className="">
              <Popover.Button ref={setabiturElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Abitur
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    07/2019 bei European School Munich
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setabiturpopperElement}
                style={abiturPopper.styles.popper}
                {...abiturPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                Schwerpunkte auf Wissenschaftliche Fächer. Informatik als Nebenfach. Abitur in: Mathematik, Physik, Geografie, Englisch, Deutsch
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="">
              <Popover.Button ref={setuebernahmeElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Übernahme der Geschäftsführung
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    09/2019 - Aktuell bei Systemhaus ONIG
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setuebernahmepopperElement}
                style={uebernahmePopper.styles.popper}
                {...uebernahmePopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Die Leitung des IT-Systemhaus ONIG übernommen. Teamleitungsfähigkeiten ausgebaut und rechtliche Grundlagen erworben. Kunden- und Projektgröße wuchsen.
                </div>
              </Popover.Panel>
            </Popover>

          </div>
        </div>

        <div className="skills-erfahrungen-year">
          <div className="skills-erfahrungen-year-number">2021</div>
          <div className="skills-erfahrungen-year-entry">

            <Popover className="">
              <Popover.Button ref={setwirhelfenElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Leitung der Softwareentwicklung
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    08/2021 - 04/2022 bei WirHelfen
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setwirhelfenpopperElement}
                style={wirhelfenPopper.styles.popper}
                {...wirhelfenPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Teamführungsfähigkeiten in größerem Team ausgebaut, komplexere DevOps Abläufe und Sicherheitsrichtlinien definiert.
                </div>
              </Popover.Panel>
            </Popover>
   
            <Popover className="">
              <Popover.Button ref={setvertragElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Entwicklung einer Vertrag Management Software
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    08/2021 - 01/2022 bei Systemhaus ONIG
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setvertragpopperElement}
                style={vertragPopper.styles.popper}
                {...vertragPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Auseinandersetzung mit rechtlichen Aspekten bei Webentwicklung wie Audit Logging, Archivierung sowie Sicherungsmöglichkeiten von kritischen Daten
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="">
              <Popover.Button ref={setgruendungElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Gründung der GmbH
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    08/2021 - Aktuell bei Open Network IT Group GmbH
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setgruendungpopperElement}
                style={gruendungPopper.styles.popper}
                {...gruendungPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Vollständige übernahme des Systemhaus ONIG und Gründung der gleichnahmigen ONIG GmbH. Übernahme des Kundenstamms sowie Kauf des alten Unternehmens
                </div>
              </Popover.Panel>
            </Popover>

          </div>
        </div>

        <div className="skills-erfahrungen-year">
          <div className="skills-erfahrungen-year-number">2022</div>
          <div className="skills-erfahrungen-year-entry">

            <Popover className="">
              <Popover.Button ref={seterpmodulElement} className="w-full">
                <div className="skills-erfahrungen-year-entry-tile">
                  <span className="skills-erfahrungen-year-entry-title">
                    Entwicklung eines ERP-Moduls
                  </span>
                  <div className="skills-erfahrungen-year-entry-description">
                    01/2022 - 07/2022 bei Fertigungsunternehmen
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={seterpmodulpopperElement}
                style={erpmodulPopper.styles.popper}
                {...erpmodulPopper.attributes.popper}
                className="z-10"
              >
                <div className="skills-erfahrungen-popover">
                  Strukturierung und Aufbau einer Enterprise Software mit Authentifizierung, Zugriffsprotokollen sowie Zertifikatsmanagement
                </div>
              </Popover.Panel>
            </Popover>

          </div>
        </div>
      </div>
    </section>
  )
}
