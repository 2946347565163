import './rating.css';

import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Rating({ count }){
    
    let icons = [];
    for(let i = 0; i < count; i++){
        icons.push({id: i, className: 'rating-item rating-selected'});
    }
    for(let i = count; i < 5; i++){
        icons.push({id: i, className: 'rating-item rating-de-selected'});
    }

    return (
        <div className="rating-wrapper">
            {icons.map(item => (
                <FontAwesomeIcon icon={faCircle} className={item.className} key={item.id}/>
            ))}
        </div>
    );
}