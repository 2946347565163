import './App.css';
import 'animate.css';
import './shared/button.css';
import { Hero } from './sections/01-hero/01-hero';
import { Skills } from './sections/03-skills/03-skills';
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { ProjectWirhelfen } from './sections/05-project-wirhelfen/05-project-wirhelfen';
import { ProjectContract } from './sections/06-project-contract/06-project-contract';
import { CodeBlock, dracula } from 'react-code-blocks'
import { MoreProjects } from './sections/08-more-projects/07-more-projects';
import { AboutMe } from './sections/09-aboutme/08-aboutme';
import { Footer } from './sections/10-footer/09-footer';
import { ProjectEp } from './sections/07-project-ep/project-ep';
import { Projecttitle } from './sections/04-projecttitle/projecttitle';

function App() {


  return (
      <ParallaxProvider>
        <Hero />
        <Skills />
        <Projecttitle />
        <ProjectWirhelfen />
        <ProjectContract />
        <ProjectEp />
        <MoreProjects />
        <AboutMe />
        <Footer />

      </ParallaxProvider>
  );
}

export default App;
