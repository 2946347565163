import './01-hero.css';
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import profilePicture from '../../assets/portrait_cut.png';
import Plx from 'react-plx';
import useMouse from '@react-hook/mouse-position'
import Typist from 'react-typist-component';

const textData1 = [
    {
      start: 'self',
      duration: '100vh',
      properties: [
        {
          startValue: 0,
          endValue: -300,
          unit: 'px',
          property: 'translateY',
        }
      ],
    },
  ];

const textData2 = [
{
    start: 'self',
    duration: '100vh',
    properties: [
    {
        startValue: 0,
        endValue: -900,
        unit: 'px',
        property: 'translateY',
    }
    ],
},
];

const textData3 = [
    {
        start: 'self',
        duration: '100vh',
        properties: [
        {
            startValue: 0,
            endValue: -700,
            unit: 'px',
            property: 'translateY',
        }
        ],
    },
    ];

export function Hero(){

    function scrollDown(){
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth',
        });
    }

    const ref = React.useRef<HTMLImageElement>(null);
    const mouse = useMouse(ref, {
        enterDelay: 100,
        leaveDelay: 100,
    })

    const [ imageCords, setImageCords ] = useState({x: 0, y: 0});
    const [ imageRotate, setimageRotate ] = useState({x: 0, y: 0});

    const sensitivity = 0.015;

    useEffect(() => {
        if(mouse.clientX && mouse.clientY){
            setImageCords({x: (window.window.innerWidth / 2 - mouse.clientX) * sensitivity, y: (window.window.innerHeight / 2 - mouse.clientY) * sensitivity});
            setimageRotate({x: -(window.window.innerWidth / 2 - mouse.clientX) * sensitivity, y: -(window.window.innerHeight / 2 - mouse.clientY) * sensitivity});
        }
    }, [mouse])

      return (
        <section ref={ref} className="hero">
            <div className="profilePicture">
                <div className="profilePicture-wrapper">
                    <img src={profilePicture}/>
                    <div className="profilePictureAfter" style={{
                        transform: `translate(${-imageCords.x!}px, ${(-imageCords.y!)}px) perspective(75em) rotateX(${imageRotate.x}deg) rotateY(${imageRotate.y}deg)`,
                    }}></div>
                </div>
            </div>
            <div className="hero-text">

                <Typist typingDelay={100} cursor={<span className="blinkingCursor">|</span>}>
                    <h1 className="hero-text-title">Julian<br />Forster</h1>
                    <h4 className="hero-text-subtitle">Full Stack Entwicklung </h4>
                </Typist>

            </div>
            <div className="bottomBar">
                <div className="scrollDown animate__animated animate__bounce animate__infinite cursor-pointer" onClick={() => scrollDown()}>
                    <span><FontAwesomeIcon icon={faAngleDown}/></span>
                </div>
            </div>
            <div className='hero-bubbles-wrapper'>
                <Plx parallaxData={ textData1 }>
                    <div className="hero-hubbles">
                        <div className="hero-bubble" style={{
                            height: 200, width: 200,
                            left: '8vw',
                            top: '10vh'
                        }} />
                    </div>
                </Plx>
                <Plx parallaxData={ textData2 }>
                    <div className="hero-hubbles">
                        <div className="hero-bubble" style={{
                            height: 50, width: 50,
                            top: '90vh',
                            left: '5vw',
                        }} />
                    </div>
                </Plx>
                <Plx parallaxData={ textData3 }>
                    <div className="hero-hubbles">
                        <div className="hero-bubble" style={{
                            height: 90, width: 90,
                            top: '75vh',
                            left: '75vw',
                        }} />
                    </div>
                </Plx>
            </div>
        </section>
    );
}