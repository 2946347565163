import '../../shared/project-page.css';
import './06-project-contract.css';

import image1 from '../../assets/projects/contract/image1.png';
import image2 from '../../assets/projects/contract/image2.png';
import image3 from '../../assets/projects/contract/image3.png';
import image4 from '../../assets/projects/contract/image4.png';
import image5 from '../../assets/projects/contract/image5.png';

import { Parallax, useParallax } from 'react-scroll-parallax';
import Plx from 'react-plx';

const textData = [
    {
      start: '.trigger2',
      duration: '30vh',
      properties: [
        {
          startValue: 30,
          endValue: 0,
          unit: 'vh',
          property: 'translateY',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: '.trigger2',
      startOffset: '250vh',
      duration: '30vh',
      properties: [
        {
          startValue: 0,
          endValue: -30,
          unit: 'vh',
          property: 'translateY',
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
        },
      ],
    },
    {
      start: '.trigger2',
      startOffset: '320vh',
      duration: '1vh',
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: 'scale',
        },
      ],
    },
  ];

export function ProjectContract(){

    const image1Ref = useParallax<HTMLDivElement>({ speed: 30}).ref;
    const image2Ref = useParallax<HTMLDivElement>({ speed: -20 }).ref;
    const image3Ref = useParallax<HTMLDivElement>({ speed: 20 }).ref;
    const image4Ref = useParallax<HTMLDivElement>({ speed: 40 }).ref;
    const image5Ref = useParallax<HTMLDivElement>({ speed: 20 }).ref;

    return (
        <div className="projectOuterWrapper contractWrapper">
            <div className='StickyText-trigger trigger2' />
                <Plx
                className="projectWrapper-custom"
                    parallaxData={ textData }
                >
            <section className="projectWrapper">
                <div className="projectNumber">2</div>
                <div className="projectText">
                    <h2 className="projectHeader">
                        Vertrag <br />Managementtool
                    </h2>
                    <div className="projectTags">
                        <div className="projectTags-tag">UI/UX</div>
                        <div className="projectTags-tag">API-Integration</div>
                        <div className="projectTags-tag">Php</div>
                        <div className="projectTags-tag">Adobe XD</div>
                    </div>
                    <div className="projectDescription">
                        <p className="mt-1">Ein Vertragsmanagementtool, für den Kunden und Dienstleister, mit Fokus auf Benutzerfreundlichkeit. Die Verträge können intern von uns oder vom Kunden selbst verwalten werden.</p>
                        <p className="mt-1">Schnittstelle zur Buchhaltung und Bank zum generieren der Rechnungen sowie einziehen der Lastschriftseinzüge.</p>
                        <p className="mt-1">Augenmerk auf Benutzerfreundlichkeit mit eigens entwickelten Grafiken und intuitiv entwickeltem Menü.</p>
                    </div>
                </div>
            </section>
            </Plx>

            <div className="projectImages">

            {/* <div ref={image1Ref} style={{marginTop: '80vh', marginRight: "2vw", width: "40vw", minWidth: "320px"}}><img className="image1" src={image1}/></div>
                <div ref={image4Ref} style={{marginTop: '80vh', marginRight: "max(250px, 35vw)", width: "20vw", minWidth: "100px"}}><img className="image1" src={image4}/></div> */}

                <div ref={image2Ref} style={{marginTop: '60vh', marginRight: "2vw", width: "40vw", minWidth: "320px"}} className="small-left"><img className="card-shadow projectImage" src={image2}/></div>
                
                <div ref={image3Ref} style={{marginTop: '60vh', marginRight: "max(100px, 1vw)", width: "20vw", minWidth: "150px"}}><img className="card-shadow projectImage" src={image3}/></div>
                <div ref={image4Ref} style={{marginTop: '10vh', marginRight: "max(200px, 20vw)", width: "20vw", minWidth: "150px"}}><img className="card-shadow projectImage" src={image4}/></div>

                <div ref={image5Ref} style={{marginTop: '140vh', marginRight: "2vw", width: "28vw", minWidth: "300px"}} className="small-left"><img className="card-shadow projectImage" src={image5}/></div>

                <div ref={image1Ref} style={{marginTop: '100vh', marginRight: "max(200px, 25vw)", width: "20vw", minWidth: "150px"}}><img className="card-shadow projectImage" src={image1}/></div>
            </div>
        </div>
    )
}